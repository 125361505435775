const data = [
  {
    id: 901,
    title: "Spotzero By Milton Mini Spin Mop, Aqua Green",
    cancelprice: "799",
    dicPersent: "95.01% Off",
    image: require("../assets/newProducts/a1.jpg"),
    slider: [
      require("../assets/newProducts/a1.jpg"),
      require("../assets/newProducts/a2.jpg"),
      require("../assets/newProducts/a3.jpg"),
      require("../assets/newProducts/a4.jpg"),
      require("../assets/newProducts/a5.jpg"),
    ],
    price: "149",
    highlight: require("../assets/newProducts/1a.png"),
  },
  {
    id: 902,
    title: "E-COSMOS Food Chopper 900ml, Steel Large Manual Hand-Press Vegetable Chopper Mixer Cutter to Cut Onion, Salad, Tomato, Potato (Pack of 1) 900ml",
    cancelprice: "299",
    dicPersent: "95.01% Off",
    image: require("../assets/newProducts/b1.webp"),
    slider: [
      require("../assets/newProducts/b1.webp"),
      require("../assets/newProducts/b2.jpg"),
      require("../assets/newProducts/b3.jpg"),
      require("../assets/newProducts/b4.jpg"),
      require("../assets/newProducts/b5.jpg"),
    ],
    price: "299",
    highlight: require("../assets/newProducts/2a.png"),
  },
  {
    id: 903,
    title: "Pigeon by Stovekraft Ultra Premium Handy Chopper with 3 Blades for Effortlessly Chopping Vegetables and Fruits for Your Kitchen (Green, 400 ml, 12420)",
    cancelprice: "299",
    dicPersent: "95.01% Off",
    image: require("../assets/newProducts/c1.webp"),
    slider: [
      require("../assets/newProducts/c1.webp"),
      require("../assets/newProducts/c2.jpg"),
      require("../assets/newProducts/c3.jpg"),
      require("../assets/newProducts/c4.jpg"),
      require("../assets/newProducts/c5.jpg"),
    ],
    price: "129",
    highlight: require("../assets/newProducts/3a.png"),
  },
  {
    id: 904,
    title: "NutriPro Juicer Mixer Grinder - Smoothie Maker - 500 Watts (3 Jars 2 Blades)",
    cancelprice: "1299",
    dicPersent: "95.01% Off",
    image: require("../assets/newProducts/d1.jpg"),
    slider: [
      require("../assets/newProducts/d1.jpg"),
      require("../assets/newProducts/d2.jpg"),
      require("../assets/newProducts/d3.jpg"),
      require("../assets/newProducts/d4.jpg"),
    ],
    price: "299",
    highlight: require("../assets/newProducts/4a.png"),
  },
  {
    id: 905,
    title: "Milton Pro Lunch Tiffin (3 Microwave Safe Inner Steel Containers, 180/320/450 ml; 1 Plastic Chutney Dabba,100 ml; 1 Aqua Steel Bottle, 750 ml, Steel Spoon and Fork) With Insulated Fabric Jacket, Black",
    cancelprice: "849",
    dicPersent: "95.01% Off",
    image: require("../assets/newProducts/e1.webp"),
    slider: [
      require("../assets/newProducts/e1.webp"),
      require("../assets/newProducts/e2.jpg"),
      require("../assets/newProducts/e3.jpg"),
      require("../assets/newProducts/e4.jpg"),
      require("../assets/newProducts/e5.jpg"),
    ],
    price: "149",
    highlight: require("../assets/newProducts/5a.png"),
  },
  {
    id: 906,
    title: "Pigeon by Stovekraft Amaze Plus Electric Kettle (14289) with Stainless Steel Body, 1.5 litre, used for boiling Water, making tea and coffee, instant noodles, soup etc. (Silver)",
    cancelprice: "599",
    dicPersent: "95.01% Off",
    image: require("../assets/newProducts/f1.jpg"),
    slider: [
      require("../assets/newProducts/f1.jpg"),
      require("../assets/newProducts/f2.jpg"),
      require("../assets/newProducts/f3.jpg"),
      require("../assets/newProducts/f4.jpg"),
      require("../assets/newProducts/f5.jpg"),
    ],
    price: "249",
    highlight: require("../assets/newProducts/6a.png"),
  },
  {
    id: 907,
    title: "Godrej aer Matic Kit (Machine + 1 Refill) - Automatic Room Fresheners with Flexi Control Spray | Violet Valley Bloom | 2200 Sprays Guaranteed | Lasts up to 60 days (225ml)",
    cancelprice: "599",
    dicPersent: "95.01% Off",
    image: require("../assets/newProducts/g1.webp"),
    slider: [
      require("../assets/newProducts/g1.webp"),
      require("../assets/newProducts/g2.jpg"),
      require("../assets/newProducts/g3.jpg"),
      require("../assets/newProducts/g4.jpg"),
      require("../assets/newProducts/g5.jpg"),
    ],
    price: "139",
    highlight: require("../assets/newProducts/7a.png"),
  },
  {
    id: 908,
    title: "SOPL-OLIVEWARE Teso Pro Lunch Box With Steel Cutlery,3 Microwave Safe Inner Steel Containers With Bpa Free Lids(290Ml,450Ml&600Ml)Plastic Pickle Box(130Ml)Steel Water Bottle(750Ml)-Blue,600 Ml",
    cancelprice: "849",
    dicPersent: "95.01% Off",
    image: require("../assets/newProducts/h1.jpg"),
    slider: [
      require("../assets/newProducts/h1.jpg"),
      require("../assets/newProducts/h2.jpg"),
      require("../assets/newProducts/h3.jpg"),
      require("../assets/newProducts/h4.jpg"),
      require("../assets/newProducts/h5.jpg"),
    ],
    price: "149",
    highlight: require("../assets/newProducts/8a.png"),
  },
  {
    id: 909,
    title: "Mistri Office Table for Home/Writing Desk for Office/Folding Table for School/Folding Study Table/Work from Home Multipurpose Table (Black) Wood",
    cancelprice: "399",
    dicPersent: "95.01% Off",
    image: require("../assets/newProducts/i1.jpg"),
    slider: [
      require("../assets/newProducts/i1.jpg"),
      require("../assets/newProducts/i2.jpg"),
      require("../assets/newProducts/i3.jpg"),
      require("../assets/newProducts/i4.jpg"),
      require("../assets/newProducts/i5.jpg"),
    ],
    price: "179",
    highlight: require("../assets/newProducts/9a.png"),
  },
  {
    id: 0.1,
    title: "GOPI Kitchen Tools For Home Kitchen Items 1000 ML, Color Black",
    cancelprice: "299",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/aa2.jpeg"),
    slider: [
      require("../assets/productOne/aa1.jpeg"),
      require("../assets/productOne/aa2.jpeg"),
      require("../assets/productOne/aa1.jpeg"),
    ],
    price: "119",
    highlight: require("../assets/productOne/aa3.png"),
    rating: require("../assets/productOne/aa4.png"),
  },
  {
    id: 0.2,
    title:
      "PYXBE Quick Spin Mop,Easy Wheels & Big Bucket with 2 Microfiber Refills, Floor Cleaning Mop with Bucket, pocha for Floor Cleaning, Mopping Set Grill Basting Mop Long qw (Blue)    ",
    cancelprice: "537",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/bb1.jpg"),
    slider: [
      require("../assets/productOne/bb1.jpg"),
      require("../assets/productOne/bb4.jpg"),
      require("../assets/productOne/bb4.jpg"),
    ],
    price: "159",
    highlight: require("../assets/productOne/bb3.png"),
    rating: require("../assets/productOne/bb2.png"),
  },
  {
    id: 1,
    title:
      "4 SACRED Plastic Grocery Container - 1200 ml  (Pack of 12, Clear)",
    cancelprice: "537",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/a3.jpeg"),
    slider: [
      require("../assets/productOne/a3.jpeg"),
      require("../assets/productOne/a5.jpeg"),
      require("../assets/productOne/a6.jpeg"),
    ],
    price: "149",
    highlight: require("../assets/productOne/a1.png"),
    rating: require("../assets/productOne/a2.png"),
  },
  {
    id: 2,
    title:
      "Auspicia Plastic Grocery Container - 750 ml  (Pack of 12, Black)",
    cancelprice: "999",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/b3.jpeg"),
    slider: [
      require("../assets/productOne/b3.jpeg"),
      require("../assets/productOne/b5.jpeg"),
      require("../assets/productOne/b6.jpeg"),
    ],
    price: "199",
    highlight: require("../assets/productOne/b1.png"),
    rating: require("../assets/productOne/b2.png"),
  },
  {
    id: 3,
    title: "Spice Set Plastic  (1 Piece)",
    cancelprice: "229",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/c3.jpeg"),
    slider: [
      require("../assets/productOne/c3.jpeg"),
      require("../assets/productOne/c4.jpeg"),
      require("../assets/productOne/c3.jpeg"),
    ],
    price: "129",
    highlight: require("../assets/productOne/c1.png"),
    rating: require("../assets/productOne/c2.png"),
  },
  {
    id: 4,
    title: "OTIZ 3000 ml Cooking Oil Dispenser Set  (Pack of 3)",
    cancelprice: "229",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/d3.jpeg"),
    slider: [
      require("../assets/productOne/d3.jpeg"),
      require("../assets/productOne/d4.jpeg"),
    ],
    price: "129",
    highlight: require("../assets/productOne/d1.png"),
    rating: require("../assets/productOne/d2.png"),
  },
  {
    id: 5,
    title:
      "3D METRO SUPER STORE Plastic Fridge Container - 1800 ml  (Pack of 5, Clear)",
    cancelprice: "249",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/e3.jpeg"),
    slider: [
      require("../assets/productOne/e3.jpeg"),
      require("../assets/productOne/e4.jpeg"),
      require("../assets/productOne/e5.jpeg"),
      require("../assets/productOne/e6.jpeg"),
      require("../assets/productOne/e7.jpeg"),
    ],
    price: "139",
    highlight: require("../assets/productOne/e1.png"),
    rating: require("../assets/productOne/e2.png"),
  },
  {
    id: 6,
    title: "Sumeet Spice Set Stainless Steel",
    cancelprice: "549",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/f3.jpeg"),
    slider: [
      require("../assets/productOne/f3.jpeg"),
      require("../assets/productOne/f4.jpeg"),
      require("../assets/productOne/f5.jpeg"),
      require("../assets/productOne/f6.jpeg"),
    ],
    price: "149",
    highlight: require("../assets/productOne/f1.png"),
    rating: require("../assets/productOne/f2.png"),
  },
  {
    id: 7,
    title: "Bear Grips 1000 ml Cooking Oil Dispenser  (Pack of 1)",
    cancelprice: "999",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/g3.jpeg"),
    slider: [
      require("../assets/productOne/g3.jpeg"),
      require("../assets/productOne/g4.jpeg"),
      require("../assets/productOne/g5.jpeg"),
      require("../assets/productOne/g6.jpeg"),
    ],
    price: "199",
    highlight: require("../assets/productOne/g1.png"),
    rating: require("../assets/productOne/g2.png"),
  },
  {
    id: 8,
    title: "ATROCK 1000 ml Cooking Oil Dispenser Set  (Pack of 2)",
    cancelprice: "563",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/h4.jpeg"),
    slider: [
      require("../assets/productOne/h4.jpeg"),
      require("../assets/productOne/h4.jpeg"),
    ],
    price: "159",
    highlight: require("../assets/productOne/h1.png"),
    rating: require("../assets/productOne/h2.png"),
  },
  {
    id: 9,
    title: "Anantam homes Spice Set Stainless Steel  (1 Piece)",
    cancelprice: "1,217",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/i3.jpeg"),
    slider: [
      require("../assets/productOne/i3.jpeg"),
      require("../assets/productOne/i4.jpeg"),
      require("../assets/productOne/i5.jpeg"),
      require("../assets/productOne/i6.jpeg"),
    ],
    price: "199",
    highlight: require("../assets/productOne/i1.png"),
    rating: require("../assets/productOne/i2.png"),
  },
  {
    id: 10,
    title:
      "Golwyn Utensil Kitchen Rack Steel Self Adhesive Kitchen Accessories Items Organizer / Kitchen Rack Stand Holder",
    cancelprice: "269",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/j3.jpeg"),
    slider: [
      require("../assets/productOne/j3.jpeg"),
      require("../assets/productOne/j4.jpeg"),
      require("../assets/productOne/j5.jpeg"),
      require("../assets/productOne/j6.jpeg"),
      require("../assets/productOne/j7.jpeg"),
    ],
    price: "129",
    highlight: require("../assets/productOne/j1.png"),
    rating: require("../assets/productOne/j2.png"),
  },
  {
    id: 11,
    title:
      "Cutting EDGE 1100 ml, 1100 ml, 585 ml, 585 ml, 585 ml, 250 ml, 250 ml Cooking Oil Dispenser  (Pack of 7)",
    cancelprice: "550",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/k3.jpeg"),
    slider: [
      require("../assets/productOne/k3.jpeg"),
      require("../assets/productOne/k4.jpeg"),
      require("../assets/productOne/k5.jpeg"),
      require("../assets/productOne/k6.jpeg"),
    ],
    price: "149",
    highlight: require("../assets/productOne/k1.png"),
    rating: require("../assets/productOne/k2.png"),
  },
  {
    id: 12,
    title: "Avastro Glass Grocery Container - 250 ml  (Pack of 12, Clear)",
    cancelprice: "449",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/l3.jpeg"),
    slider: [
      require("../assets/productOne/l3.jpeg"),
      require("../assets/productOne/l4.jpeg"),
      require("../assets/productOne/l5.jpeg"),
      require("../assets/productOne/l6.jpeg"),
      require("../assets/productOne/l7.jpeg"),
    ],
    price: "149",
    highlight: require("../assets/productOne/l1.png"),
    rating: require("../assets/productOne/l2.png"),
  },
  {
    id: 13,
    title: "500 ml Cooking Oil Dispenser Set  (Pack of 7)",
    cancelprice: "279",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/m3.jpeg"),
    slider: [
      require("../assets/productOne/m3.jpeg"),
      require("../assets/productOne/m4.jpeg"),
      require("../assets/productOne/m5.jpeg"),
      require("../assets/productOne/m6.jpeg"),
      require("../assets/productOne/m7.jpeg"),
    ],
    price: "129",
    highlight: require("../assets/productOne/m1.png"),
    rating: require("../assets/productOne/m2.png"),
  },
  {
    id: 14,
    title:
      "POLYSET Plastic Grocery Container - 175 ml, 1475 ml, 225 ml, 1050 ml, 540 ml, 295 ml  (Pack of 14, Blue)",
    cancelprice: "479",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/n3.jpeg"),
    slider: [
      require("../assets/productOne/n3.jpeg"),
      require("../assets/productOne/n4.jpeg"),
      require("../assets/productOne/n5.jpeg"),
      require("../assets/productOne/n6.jpeg"),
      require("../assets/productOne/n7.jpeg"),
    ],
    price: "139",
    highlight: require("../assets/productOne/n1.png"),
    rating: require("../assets/productOne/n2.png"),
  },
  {
    id: 15,
    title: "DN BROTHERS Glass Pickle Jar - 250 ml  (Pack of 2, Clear)",
    cancelprice: "479",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/o3.jpeg"),
    slider: [
      require("../assets/productOne/o3.jpeg"),
      require("../assets/productOne/o4.jpeg"),
    ],
    price: "149",
    highlight: require("../assets/productOne/o1.png"),
    rating: require("../assets/productOne/o2.png"),
  },
  {
    id: 16,
    title: "Avastro Glass Cookie Jar - 350 ml  (Pack of 3, Clear)",
    cancelprice: "369",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/p3.jpeg"),
    slider: [
      require("../assets/productOne/p3.jpeg"),
      require("../assets/productOne/p4.jpeg"),
      require("../assets/productOne/p5.jpeg"),
      require("../assets/productOne/p6.jpeg"),
      require("../assets/productOne/p7.jpeg"),
    ],
    price: "139",
    highlight: require("../assets/productOne/p1.png"),
    rating: require("../assets/productOne/p2png.png"),
  },
  {
    id: 17,
    title:
      "EagleWell Plastic Fridge Container - 2500 ml  (Pack of 4, White)",
    cancelprice: "369",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/q3.jpeg"),
    slider: [
      require("../assets/productOne/q3.jpeg"),
      require("../assets/productOne/q4.jpeg"),
      require("../assets/productOne/q5.jpeg"),
      require("../assets/productOne/q6.jpeg"),
    ],
    price: "139",
    highlight: require("../assets/productOne/q1.png"),
    rating: require("../assets/productOne/q2.png"),
  },
  {
    id: 18,
    title:
      "hurrio Drain Stainer Strainer (Green Plastic Fruit & Vegetable Basket  (Multicolor)",
    cancelprice: "384",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/r2.jpeg"),
    slider: [
      require("../assets/productOne/r2.jpeg"),
      require("../assets/productOne/r4.jpeg"),
      require("../assets/productOne/r3.jpeg"),
    ],
    price: "119",
    highlight: require("../assets/productOne/r1.png"),
    // rating: require("../assets/productOne/q2.png"),
  },
  {
    id: 19,
    title:
      "Flipkart SmartBuy Steel Tea Coffee & Sugar Container - 800 ml  (Pack of 3, Multicolor)",
    cancelprice: "649",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/s3.jpeg"),
    slider: [
      require("../assets/productOne/s3.jpeg"),
      require("../assets/productOne/s4.jpeg"),
      require("../assets/productOne/s5.jpeg"),
      require("../assets/productOne/s6.jpeg"),
    ],
    price: "169",
    highlight: require("../assets/productOne/s1.png"),
    rating: require("../assets/productOne/s2.png"),
  },
  {
    id: 20,
    title: "2Mech Plastic Grocery Container - 1800 ml  (Blue)",
    cancelprice: "159",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/t3.jpeg"),
    slider: [
      require("../assets/productOne/t3.jpeg"),
      require("../assets/productOne/t4.jpeg"),
    ],
    price: "119",
    highlight: require("../assets/productOne/t1.png"),
    rating: require("../assets/productOne/t2.png"),
  },
  {
    id: 21,
    title: "Shree Shop Spice Set Polypropylene  (16 Piece)",
    cancelprice: "999",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/u3.jpeg"),
    slider: [
      require("../assets/productOne/u3.jpeg"),
      require("../assets/productOne/u4.jpeg"),
    ],
    price: "199",
    highlight: require("../assets/productOne/u1.png"),
    rating: require("../assets/productOne/u2.png"),
  },
  {
    id: 22,
    title: "KUBER INDUSTRIES Condiment Set Plastic  (1 Piece)",
    cancelprice: "678",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/v3.jpeg"),
    slider: [
      require("../assets/productOne/v3.jpeg"),
      require("../assets/productOne/v4.jpeg"),
    ],
    price: "179",
    highlight: require("../assets/productOne/v1.png"),
    rating: require("../assets/productOne/v2.png"),
  },
  {
    id: 23,
    title: "WIN INTERNATIONAL Spice Set Plastic",
    cancelprice: "399",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/w2.jpeg"),
    slider: [
      require("../assets/productOne/w3.jpeg"),
      require("../assets/productOne/w2.jpeg"),
    ],
    price: "139",
    highlight: require("../assets/productOne/w1.png"),
    // rating: require("../assets/productOne/v2.png"),
  },
  {
    id: 24,
    title:
      "Arcane 6 S.S Blade 1 L Full Dark Black Square Chopper for Kitchen Vegetable Chopper Vegetable & Fruit Chopper  (1 XXL Chopper 1000ml)",
    cancelprice: "399",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/x3.jpeg"),
    slider: [
      require("../assets/productOne/x3.jpeg"),
      require("../assets/productOne/x4.jpeg"),
    ],
    price: "139",
    highlight: require("../assets/productOne/x1.png"),
    rating: require("../assets/productOne/x2.png"),
  },
  {
    id: 25,
    title: "Kreyam's Set of 6 Pattern Discs Kitchen Press",
    cancelprice: "260",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/y3.jpeg"),
    slider: [
      require("../assets/productOne/y3.jpeg"),
      require("../assets/productOne/y4.jpeg"),
    ],
    price: "139",
    highlight: require("../assets/productOne/y1.png"),
    rating: require("../assets/productOne/y2.png"),
  },
  {
    id: 26,
    title:
      "DeoDap Multipurpose 13-in-1 Jumbo Manual Vegetable and Fruit Chopper Cutter Grater Slicer, Dicer, Fruit Slicer Vegetable & Fruit Grater & Slicer  (1 X Chopper, 12 X Blades)",
    cancelprice: "415",
    dicPersent: "95.01% Off",
    image: require("../assets/productOne/z3.jpeg"),
    slider: [
      require("../assets/productOne/z3.jpeg"),
      require("../assets/productOne/z4.jpeg"),
    ],
    price: "149",
    highlight: require("../assets/productOne/z1.png"),
    rating: require("../assets/productOne/z2.png"),
  },
  {
    id: 27,
    title:
      "SHAYONA SALES Stainless Steel Coconut ScraperCoconut Scraper Shredder Grater for Kitchen/Home Stainless Steel with Vacuum Base, Hand held Manual Home Coconut Scraper  (1)",
    cancelprice: "415",
    dicPersent: "95.01% Off",
    image: require("../assets/productTwo/a3.jpeg"),
    slider: [
      require("../assets/productTwo/a3.jpeg"),
      require("../assets/productTwo/a4.jpeg"),
    ],
    price: "149",
    highlight: require("../assets/productTwo/a1.png"),
    rating: require("../assets/productTwo/a2.png"),
  },
  {
    id: 28,
    title:
      "DIVINE HUB DIVINE HUB Stainless Steel Multi-Function Manual Garlic Crusher Presser Portable Ginger Press Mincer Grinding Squeeze Slicer Chopper Cutter for Kitchen Pack of 1 Vegetable & Fruit Scraper  (1)",
    cancelprice: "515",
    dicPersent: "95.01% Off",
    image: require("../assets/productTwo/b3.jpeg"),
    slider: [
      require("../assets/productTwo/b3.jpeg"),
      require("../assets/productTwo/b4.jpeg"),
    ],
    price: "159",
    highlight: require("../assets/productTwo/b1.png"),
    rating: require("../assets/productTwo/b2.png"),
  },
  {
    id: 29,
    title:
      "Kaira Enterprises Multifuctional Rotate Vegetable Cutter Basket,Peeler, Plastic,Combo, Drainer Fruit Cutter with Drain Basket Kitchen Greater Slicer Vegetable Slicer  (1)",
    cancelprice: "315",
    dicPersent: "95.01% Off",
    image: require("../assets/productTwo/c3.jpeg"),
    slider: [
      require("../assets/productTwo/c3.jpeg"),
      require("../assets/productTwo/c4.jpeg"),
    ],
    price: "139",
    highlight: require("../assets/productTwo/c1.png"),
    rating: require("../assets/productTwo/c2.png"),
  },
  {
    id: 30,
    title:
      "Fancy 12 in 1 Chipser, Slicer, Chopper, Grater Vegetable Chopper  (BOX, 12 Diffrent Blade)",
    cancelprice: "455",
    dicPersent: "95.01% Off",
    image: require("../assets/productTwo/d3.jpeg"),
    slider: [
      require("../assets/productTwo/d3.jpeg"),
      require("../assets/productTwo/d4.jpeg"),
    ],
    price: "149",
    highlight: require("../assets/productTwo/d1.png"),
    rating: require("../assets/productTwo/d2.png"),
  },
  {
    id: 31,
    title:
      "Zenex store EasySlice and Eggs our 2 in 1 Egg Cutter and Chopper Perfect Salads, Sandwiches Egg Slicer  (1 Egg Slicer .)",
    cancelprice: "999",
    dicPersent: "95.01% Off",
    image: require("../assets/productTwo/e3.jpeg"),
    slider: [
      require("../assets/productTwo/e3.jpeg"),
      require("../assets/productTwo/e4.jpeg"),
    ],
    price: "199",
    highlight: require("../assets/productTwo/e1.png"),
    rating: require("../assets/productTwo/e2.png"),
  },
  {
    id: 32,
    title:
      "Flipkart SmartBuy Kitchen Steel Vegetable & Fruit Grater Black Vegetable & Fruit Grater  (1 * Zester)",
    cancelprice: "399",
    dicPersent: "95.01% Off",
    image: require("../assets/productTwo/f3.jpeg"),
    slider: [
      require("../assets/productTwo/f3.jpeg"),
      require("../assets/productTwo/f4.jpeg"),
    ],
    price: "149",
    highlight: require("../assets/productTwo/f1.png"),
    rating: require("../assets/productTwo/f2.png"),
  },
  {
    id: 33,
    title:
      "Hozon Best Kitchen Gadget For Veg Meat Fish Cutting Etc Kitchen Tool Set  (Wood Brown, Silver, Chopper, Cutting Board, Knife, Cutter)",
    cancelprice: "786",
    dicPersent: "95.01% Off",
    image: require("../assets/productTwo/g3.jpeg"),
    slider: [
      require("../assets/productTwo/g3.jpeg"),
      require("../assets/productTwo/g4.jpeg"),
    ],
    price: "179",
    highlight: require("../assets/productTwo/g1.png"),
    rating: require("../assets/productTwo/g2.png"),
  },
  {
    id: 34,
    title:
      "REPISH Water Dispenser Pump, USB Charging Compatible for 5L to 20 Litre Can Centrifugal Water Pump Bottled Water Dispenser",
    cancelprice: "364",
    dicPersent: "95.01% Off",
    image: require("../assets/productTwo/h3.jpeg"),
    slider: [
      require("../assets/productTwo/h3.jpeg"),
      require("../assets/productTwo/h4.jpeg"),
    ],
    price: "139",
    highlight: require("../assets/productTwo/h1.png"),
    rating: require("../assets/productTwo/h2.png"),
  },
  {
    id: 35,
    title:
      "INFINITY CHOICE Cleaver Cutter-2 in 1 Superior Quality Kitchen Knife with Spring Action Multipurpose Vegetable Scissor 1 pc Vegetable Chopper  (1 CUTTER)",
    cancelprice: "184",
    dicPersent: "95.01% Off",
    image: require("../assets/productTwo/i3.jpeg"),
    slider: [
      require("../assets/productTwo/i3.jpeg"),
      require("../assets/productTwo/i4.jpeg"),
    ],
    price: "119",
    highlight: require("../assets/productTwo/i1.png"),
    rating: require("../assets/productTwo/i2.png"),
  },
  {
    id: 36,
    title:
      "Rexmon Vegetable Chipser French Fries & Finger Chips Vegetable Chopper Vegetable Chopper  (1)",
    cancelprice: "449",
    dicPersent: "95.01% Off",
    image: require("../assets/productTwo/j3.jpeg"),
    slider: [
      require("../assets/productTwo/j3.jpeg"),
      require("../assets/productTwo/j4.jpeg"),
    ],
    price: "149",
    highlight: require("../assets/productTwo/j1.png"),
    rating: require("../assets/productTwo/j2.png"),
  },
];
export default data;
